

 .svg-pi-wrapper {
    position: relative;
  }
  
  .svg-pi {
    transform: rotate(0deg); /* Fix the orientation */
  }
  
  /* Animated spinner version */
  .svg-pi-indicator--spinner {
    animation: spinner .75s linear infinite;
    transform-origin: center;
  }
  
  .svg-pi-label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .svg-pi-label__progress {
    font-size: 1.5em;
    font-weight: bold;
  }
  
  .svg-pi-label__loading,
  .svg-pi-label__progress {
    display: block;
  }
  
  /* Spinner animation */
  @keyframes spinner {
    0% {
      transform: rotate(0)
    }
    100% {
      transform: rotate(360deg)
    }
  }