@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "./modal.css";

:root {
  /* Neutral base colors */
  --white: #ffffff;
  --white-2: #FAFCFD;
  --shadow: rgba(52, 69, 79, 0.2); /* Softer shadow with grey tones */
  --text-color: #333333; /* Darker text for better contrast */
  --text-table: #464646;
  --black-2: #002C49;
  --border-color: #CFD8DF;
  --border-dark: #226FA1;
  --border-light: #B1CFE2;
  --border-light-3: #A0A0A0;
  --table-header: #E4E4E4;
  --text-gray-2: #A4A4A4;

  /* Primary theme colors */
  --primary: #58788C; /* Soft blue-grey */
  --light-primary: #8FA4B1; /* Lighter shade of primary color */
  --active: #5A9B9E; /* Muted teal for accent color */
  
  /* Form-focused colors */
  --background: #FAFCFD; /* Off-white background for form areas */
  --background-2: #D8D5EE; /* Pale grey for different sections */
  --form-text-color: #007BFF; /* Bright blue for interactive elements */
  
  /* Utility colors */
  --dark-gray: #DEDEDE; /* For borders and lines */
  --dark-blue: #1F3A65; /* Navy blue for corporate feel */
  --purple-2: #226FA1; /* Modest coral for warm accent */
}

/* Additional elements where new colors can be applied */
body {
  background-color: var(--white-2);
  font-family: "Open Sans", sans-serif;
}

.pdf-pagination {
  border-right: 1px solid var(--dark-gray);
}

.pdf-display {
  border-left: 1px solid var(--dark-gray);
}

.form-block {
  background-color: var(--background);
}

.primary-btn {
  background-color: var(--primary);
  color: var(--white); /* Ensures text is readable on buttons */
  box-shadow: 0 2px 4px 0 var(--shadow); /* Subtle shadow for depth */
}


/* Accessibility improvements */
.text-color {
  color: var(--text-color); /* Make sure this is dark enough for white backgrounds */
}

/* New Error and Success variables */
--error: #D32F2F; /* Standard red for errors */
--success: #388E3C; /* Green for success messages */

.error-text {
  color: var(--error);
}

.success-text {
  color: var(--success);
}

.bg-row-odd {
  background-color: var(--background-2);
}

.bg-active {
  background-color: var(--active);
}

/* Scrollbar styling for light theme */
::-webkit-scrollbar-thumb {
  background-color: var(--primary);
}

::-webkit-scrollbar-track {
  background-color: var(--white-2);
}

* {
  scrollbar-color: var(--primary) var(--white-2);
}

/* For Microsoft Edge */
/* Edge uses the same scrollbar styling as Firefox, so the Firefox styles should work here */

* {
  scroll-behavior: smooth;
}
body {
  background-color: whitesmoke;
  font-family: "Open Sans", sans-serif;
  background-color: #D8D5EE;
}

.top-navbar {
  width: 100%;
  padding: 1rem;
  border-bottom: 1px solid lightgrey;
}

.pdf-pagination {
  border-right: 1px solid lightgrey;
  overflow: auto;
  height: 100vh;
}
.pdf-page-sec > div {
  text-align: center;
  box-shadow: 0px 0px 5px 0px #dedede;
  font-weight: 700;
  margin: 5px 0;
  cursor: pointer;
}
.pdf-page-sec > div > p {
  margin: 5px 0 !important;
}
.react-pdf__Page {
  background-color: whitesmoke !important   ;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pdf-display {
  border-left: 1px solid lightgrey;
  padding: 1rem;
}

.loader-block {
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-block {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
}
/* Force Css Inject */

button.btn.btn-info.btn-add.col-xs-12 {
  width: 20% !important;
}

[type="submit"]:not(:disabled) {
  font-size: 1.5rem !important;
  width: 100%;
  color: white !important;
}

/* Force Css Inject */

/* skeleton */

.loading-custom {
  background-color: #ededed;
  background: linear-gradient(
    100deg,
    rgba(255, 255, 255, 0) 40%,
    rgba(255, 255, 255, .5) 50%,
    rgba(255, 255, 255, 0) 60%
  ) #ededed;
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
}

@keyframes loading {
  to {
    background-position-x: -20%;
  }
}

.custom-description {
  height: 50vh;
  border-radius: 4px;
  /* animation-delay: .06s; */
}

/* 1rem = 16px */

.custom-title {
  min-height: 6rem;
  border-radius: 4px;
  animation-delay: .10s;
  margin-top: 2rem;
}
.custom-card{
  height: 10rem;
  width: 100%;
  border-radius: 4px;
}

.custom-modal-skeleton{
  height: 10rem;
  /* min-width: 500px; */
  /* height: 10rem; */
  /* border-radius: .2rem; */
}

.custom-modal-wrapper{
   min-width: 46rem;
}

.mb-0{
  margin-bottom: 0;
}
.mb-1{
  margin-bottom: 1rem;
}
.mb-2{
  margin-bottom: 2rem;
}
.mb-3{
  margin-bottom: 3rem;
}
.mb-10{
  margin-bottom: 2.5rem
}

.mb-14{
  margin-bottom: 3.5rem
}

.-mt-10{
  margin-top: -2.5rem
}

.-mt-12{
  margin-top: -3rem
}

.-mt-16{
  margin-top: -4rem
}

.-mt-2{
  margin-top: -0.5rem
}

.-mt-6{
  margin-top: -1.5rem
}

.mt-4{
  margin-top: 4rem;
}

.mt-10{
	margin-top: 2.5rem; /* 40px */
}

.mt-16{
  margin-top: 4rem
}
.mt-17{
  margin-top: 4.125rem;
}

.ml-10{
  margin-left: 2.5rem
}

.ml-20{
  margin-left: 5rem
}

.ml-28{
  margin-left: 7rem
}

.ml-2{
  margin-left: 0.5rem
}

.ml-3{
  margin-left: 0.75rem
}

.ml-4{
  margin-left: 1rem
}

.ml-5{
  margin-left: 1.25rem
}

.ml-6{
  margin-left: 1.5rem
}

.ml-7{
  margin-left: 1.75rem
}

.ml-8{
  margin-left: 2rem
}

.ml-9{
  margin-left: 2.25rem
}

.mr-0{
  margin-right: 0px
}

.mr-2{
  margin-right: 0.5rem
}
.m-0{
  margin: 0px
}


.my-3{
  margin-top: 0.75rem; /* 12px */
  margin-bottom: 0.75rem; /* 12px */
}

.my-4{
  margin-top: 1rem; /* 16px */
  margin-bottom: 1rem; /* 16px */
}

.my-5{
  margin-top: 1.25rem; /* 20px */
  margin-bottom: 1.25rem; /* 20px */
}
.my-10{
	margin-top: 2.5rem; /* 40px */
}

.font-16-700{
  font-weight: 700;
  /* font-size: 16px; */
  font-size: 1rem;

}
.font-14-16-400{
  font-size: 0.875rem;
  line-height: 16.94px;
  font-weight: 400;
}
.font-12-14-400{
  font-size: 0.75rem;
  line-height: 14px;
  font-weight: 400;
}
.font-16-600{
  font-weight: 600;
  /* font-size: 16px; */
  font-size: 1rem;

}

.font-17-23-400{
  /* font-size: 17px; */
  font-size: 1.0625rem;
  line-height: 23.15px;
  font-weight: 400;
}

.font-18-600-24{
  font-weight: 600;
  /* font-size: 18px; */
  font-size: 1.25rem !important;
  line-height: 24.51px;
}

.font-18-400-24{
  font-weight: 400;
  /* font-size: 18px; */
  font-size: 1.25rem;
  line-height: 24.51px;
}


.font-24-32-600{
  font-weight: 600;
  /* font-size: 24px; */
  font-size: 1.5rem;

  line-height: 32.68px;
}

.font-21-31-400{
  font-weight: 400;
  font-size: 1.3125rem;
  line-height: 31.5px;
}
.font-23-34-400{
  font-weight: 400;
  /* font-size: 23px; */
  font-size: 1.4375rem;
  /* line-height: 34.5px; */
  line-height: 2.1563rem;
}

.font-20-30-400{
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.875rem;
}

.font-16-21-400{
  font-weight: 400;
  font-size: 1rem;
  /* font-size: 16px; */

  line-height: 21.79px;
}

.font-16-24-400{
  font-weight: 400;
  font-size: 1rem;
  /* font-size: 16px; */
  /* line-height: 2px; */
}
.font-19-28-400{
  font-weight: 400;
  font-size: 1.1875rem;
  /* font-size: 16px; */
  /* line-height: 28.5px; */
}

.font-27-36-400{
  font-weight: 400;
  /* font-size: 27px; */
  font-size: 1.6875rem;

  line-height: 36.77px;
}

.font-27-40-400{
  font-weight: 400;
  font-size: 1.5rem;
  /* line-height: 40px; */

}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.font-14-19-400{
  font-weight: 400;
  /* font-size: 14px; */
  font-size: 0.875rem;

  line-height: 19.07px;
}

.lineheight-28{
  line-height: 28px;
}
.lineheight-21{
  line-height: 21.79px;
}

.text-white {
  color: var(--white);
}
.text-white-2 {
  color: var(--white-2);
}
.text-grey {
  color: var(--text-color);
}
.text-black-2 {
  color: var(--black-2);
}
.text-blue{
  color: var(--form-text-color);
}
.text-table{
  color: var(--text-table);
}
.text-gray-2{
  color: var(--text-gray-2);
}
.text-red{
 color: #E32626
}
.text-table-header{
  color: #333333;
}
.capitalize {
  text-transform: capitalize;
}

.border {
  border: 2px solid black;
}

.border-input{
  border: 1px solid #5E718D;
}
.cursor-pointer{
  cursor: pointer;
}
.cursor-resize{
  cursor: col-resize;
}

.cursor-not-allowed{
  cursor:not-allowed;
}
.w-full{
  width: 100% !important;
}
.w-screen{
  width: 100vw;
}
.w-auto{
  width: auto;
}
.w-half{
  width: 50%;
}

.w-1\/2{
  width: 50%
}

.flex-wrap{
  flex-wrap: wrap
}

.hidden{
  display: none;
}
.w-200{
  width: 200px;
  /* width: 12.5rem; */
}

.w-208{
  width: 13rem;
}
.w-267{
  width: 16.6875rem;
}

.w-3{
  width: 0.1875rem;
}

.w-15{
  width: 1rem;;
}

.w-105{
 width:6.5625rem;
}

.w-242{
  width: 15.125rem;
  
}
.w-43{
  width: 2.6875rem;
}
.w-62{
  width: 3.875rem;
}
.w-93{
  width: 5.8125rem !important;
}
.w-98{
  /* width: 98px !important; */
  width: 6.125rem !important;
}
.w-92{
  /* width: 98px !important; */
  width: 5.75rem !important;
}
.w-182{
  width: 11.375rem;
}
.min-w-133{
  /* width: 8.3125rem; */
  min-width: 8.3125rem;
}
.max-w-full{
  max-width: 100%;
}
.w-319{
  width: 319px;
}
.w-381{
  width: 30rem;
}
.w-333{
  width: 300px;
  /* width: 18.75rem; */
}

.h-full{
  height: 100%;
}
.h-screen{
  height: 100vh;
}
.h-55{
  height: 55px;
}
/* .w-1\/2{ */
.h-\[50\]{
  height: 3.125rem;
}
.h-24{
  /* height: 24px; */
  height: 1.5rem;

}
.h-41{
  height: 2.5625rem;
}
.h-42{
    /* height: 42px; */
    height: 2.625rem;
}
.h-44{
  height: 2.75rem;
}
.h-36{
  height: 2.25rem;
}
.h-38{
  /* height: 38px; */
  height: 2.375rem;
}
.h-35{
  height: 2.1875rem;
}
.h-39{
  height: 2.4375rem;
}
.h-46{
  height: 2.875rem;
}
.h-49{
  height: 3.0625rem;
}
.h-61{
  height: 3.8125rem;
}
.h-67{
  height: 4.1875rem;
}
.size-px{
  width: 1px;
  height: 1px;
}
/* size-0.5 */
.size-0-5 {
  width: 0.125rem; /* 2px */
  height: 0.125rem; /* 2px */
}

.size-1{
  width: 0.25rem; /* 4px */
  height: 0.25rem; /* 4px */
}

/* size-1.5 */
.size-1-5{
  width: 0.375rem; /* 6px */
  height: 0.375rem; /* 6px */
}

.size-2{
  width: 0.5rem; /* 8px */
  height: 0.5rem; /* 8px */
}

.size-10{
  width: 2.5rem; /* 40px */
  height: 2.5rem; /* 40px */
}

.size-4{
  width: 1rem;
  height: 1rem
}

.size-5{
  width: 1.25rem;
  height: 1.25rem
}

.size-20{
	width: 5rem; /* 80px */
  height: 5rem; /* 80px */
}

.size-18{
  width: 1.125rem;
  height: 1.125rem;
}

.size-30{
  width: 1.875rem;
  height: 1.875rem;
}

.size-23{
  width: 1.4375rem;
  height: 1.4375rem;
}

.size-128{
  width: 8rem;
  height: 8rem;
}

.box-shadow{
  box-shadow: 0px -6px 9px 8px #00000040;
}

.box-shadow-2{
  box-shadow: 0px 6px 12px 0px #00447133;
}

.bg-red{
  background-color: red;
}
.bg-light-red{
  background-color: #FFF6F6;
}
.bg-background{
  background-color: var(--background-2);
}
.bg-background-2{
  background-color: var(--background-2);
}
.bg-none{
background: none;
}
.bg-tb-header{
  background-color: var(--table-header);
}
.bg-row-odd{
  background-color: #F5F5F5;
}
.bg-row-even{
  background-color: #ffffff;
}
.bg-white{
  background-color: #ffffff;
}
.bg-transparent{
  background-color: transparent;
}
.bg-primary{
  background-color: var(--primary);
}
.bg-dark-gray{
  background-color: var(--dark-gray);
}
.bg-divider{
  background-color: #9996B7;
}
.bg-view-pdf{
  background-color: #3C394D;
}
.bg-dark-blue{
  background-color: var(--dark-blue);
}
.bg-light-primary{
  background-color: var(--light-primary);
}
.bg-active{
  background-color: var(--border-dark);
}
.bg-active-sub-menu{
  background-color: var(--active);
}
.bg-purple-2{
  background-color: var(--purple-2);
}
.absolute{
  position: absolute;
}
.relative{
  position: relative;
}
.fixed{
  position: fixed;
}
.sticky{
  position:sticky;
}
.inset-0{
  inset: 0px
}

.top-0{
  top: 0px;
}
.top-px{
  top: 1px;
}
.top-0-5{
	top: 0.125rem; /* 2px */
}
.top-1{
  top: 0.25rem; /* 4px */
}
.top-1-5{
	top: 0.375rem; /* 6px */
}
.top-2{
  top: 0.5rem; /* 8px */
}
.top-2-5{
  top: 0.625rem; /* 10px */
}
.top-3{
  top: 0.75rem; /* 12px */
}
.top-4{
	top: 1rem; /* 16px */
}

.top-10{
  top: 2.5rem; /* 40px */
}

.top-14{
  top: 3.5rem; /* 56px */
}

.top-24{
  top: 6rem; /* 96px */
}

.top-32{
	top: 8rem; /* 128px */
}
.bottom-0{
  bottom: 0px;
}
.bottom-px{
  bottom: 1px;
}
.bottom-0-5{
	bottom: 0.125rem; /* 2px */
}
.bottom-1{
  bottom: 0.25rem; /* 4px */
}
.bottom-1-5{
	bottom: 0.375rem; /* 6px */
}
.bottom-2{
  bottom: 0.5rem; /* 8px */
}
.bottom-2-5{
  bottom: 0.625rem; /* 10px */
}
.bottom-3{
  bottom: 0.75rem; /* 12px */
}
.bottom-4{
	bottom: 1rem; /* 16px */
}
.bottom-10{
  bottom: 2.5rem; /* 40px */
}
.bottom-32{
	bottom: 8rem; /* 128px */
}

.bottom-40{
  bottom: 10rem; /* 160px */
}

.-bottom-16px{
  bottom: -2rem;
}

.-bottom-48{
  bottom: -3rem; 
}

.left-0{
  left: 0;
}
.left-px{
  left: 1px;
}
.left-0-5{
	left: 0.125rem; /* 2px */
}
.left-1{
  left: 0.25rem; /* 4px */
}
.left-1-5{
	left: 0.375rem; /* 6px */
}
.left-2{
  left: 0.5rem; /* 8px */
}
.left-2-5{
  left: 0.625rem; /* 10px */
}
.left-3{
  left: 0.75rem; /* 12px */
}
.left-4{
	left: 1rem; /* 16px */
}
.right-0{
  right: 0px;
}
.right-px{
  right: 1px;
}
.right-0-5{
	right: 0.125rem; /* 2px */
}
.right-1{
  right: 0.25rem; /* 4px */
}
.right-1-5{
	right: 0.375rem; /* 6px */
}
.right-2{
  right: 0.5rem; /* 8px */
}
.right-2-5{
  right: 0.625rem; /* 10px */
}
.right-3{
  right: 0.75rem; /* 12px */
}
.right-4{
	right: 1rem; /* 16px */
}
.right-100{
  right: 6.25rem;
}
.flex{
  display: flex;
}
.flex-1{
  flex: 1 ;
}

.flex-col{
  flex-direction: column;
}

.items-center{
  align-items: center; 
}

.justify-start{
  justify-content: start; 
}
.justify-center{
  justify-content: center; 
}
.justify-between{
  justify-content: space-between; 
}
.justify-around{
  justify-content: space-around; 
}
.justify-evenly{
  justify-content: space-evenly; 
}
.justify-stretch{
  justify-content: stretch; 
}
.justify-end{
  justify-content: end; 
}

.gap-3px{
  gap: 0.1875rem;
}

.gap-10{
  /* gap: 10px; */
  gap: 0.625rem;
}
.gap-20{
  /* gap: 20px; */
  gap: 1.25rem;

}
.gap-30{
  /* gap: 30px; */
  gap: 1.875rem;
}

.gap-48{
  /* gap: 30px; */
  gap: 3rem;
}


.list-none{
  list-style-type: none;
}

.p-1{
  padding: 0.25rem; 
}
.p-2{
  padding: 0.5rem; 
}
.p-3{
  padding: 0.75rem; 
}
.p-4{
  padding: 1rem 
}
.p-5{
  padding: 1.25rem; 
}
.p-6{
  padding: 1.5re; 
}
.p-7{
  padding: 1.75rem; 
}
.p-8{
  padding: 2rem 
}
.p-9{
  padding: 2.25rem; 
}
.p-10{
  padding: 2.5rem; 
}
.p-11{
  padding: 2.75rem; 
}
.p-12{
  padding: 3rem;
}
.p-13{
  padding: 3.25rem; 
}
.p-14{
  padding: 3.5rem; 
}
.p-15{
  padding: 3.75rem; 
}
.p-16{
  padding: 4rem;
}

.px-\[10px\]{
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.py-\[20px\]{
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.px-1{
  padding-left: 0.25rem;
  padding-right: 0.25rem; 
}
.px-2{
  padding-left: 0.5rem;
  padding-right: 0.5rem; 
}
.px-3{
  padding-left: 0.75rem;
  padding-right: 0.75rem; 
}
.px-4{
  padding-left: 1rem;
  padding-right: 1rem; 
}
.px-5{
  padding-left: 1.25rem;
  padding-right: 1.25rem; 
}
.px-6{
  padding-left: 1.5rem;
  padding-right: 1.5em; 
}
.px-7{
  padding-left: 1.75rem;
  padding-right: 1.75rem; 
}
.px-8{
  padding-left: 2rem;
  padding-right: 2rem; 
}
.px-9{
  padding-left: 2.25rem;
  padding-right: 2.25rem; 
}
.px-10{
  padding-left: 2.5rem;
  padding-right: 2.5rem; 
}
.px-11{
  padding-left: 2.75rem;
  padding-right: 2.75rem; 
}
.px-12{
  padding-left: 3rem;
  padding-right: 3rem; 
}
.px-13{
  padding-left: 3.25rem;
  padding-right: 3.25rem; 
}
.px-14{
  padding-left: 3.5rem;
  padding-right: 3.5rem; 
}
.px-15{
  padding-left: 3.75rem;
  padding-right: 3.75rem; 
}
.px-16{
  padding-left: 4rem;
  padding-right: 4rem; 
}

.py-1{
  padding-top: 0.25rem;
  padding-bottom: 0.25rem; 
}
.py-2{
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; 
}
.py-3{
  padding-top: 0.75rem;
  padding-bottom: 0.75rem; 
}
.py-4{
  padding-top: 1rem;
  padding-bottom: 1rem; 
}
.py-5{
  padding-top: 1.25rem;
  padding-bottom: 1.25rem; 
}
.py-6{
  padding-top: 1.5rem;
  padding-bottom: 1.5em; 
}
.py-7{
  padding-top: 1.75rem;
  padding-bottom: 1.75rem; 
}
.py-8{
  padding-top: 2rem;
  padding-bottom: 2rem; 
}
.py-9{
  padding-top: 2.25rem;
  padding-bottom: 2.25rem; 
}
.py-10{
  padding-top: 2.5rem;
  padding-bottom: 2.5rem; 
}
.py-11{
  padding-top: 2.75rem;
  padding-bottom: 2.75rem; 
}
.py-12{
  padding-top: 3rem;
  padding-bottom: 3rem; 
}
.py-13{
  padding-top: 3.25rem;
  padding-bottom: 3.25rem; 
}
.py-14{
  padding-top: 3.5rem;
  padding-bottom: 3.5rem; 
}
.py-15{
  padding-top: 3.75rem;
  padding-bottom: 3.75rem; 
}
.py-16{
  padding-top: 4rem;
  padding-bottom: 4rem; 
}

.pr-2{
  padding-right: 0.5rem
}

.pr-3{
  padding-right: 0.75rem
}

.pr-4{
  padding-right: 1rem
}
.divide-y{
  border-top-width: 1px;
  border-bottom-width: 0px;
}

.border-b{
  border: 1px solid;
  border-left: none;
  border-right: none;
  border-top: none;

}

.br-4{
  border-radius: 0.25rem;
}
.br-6{
  border-radius: 0.375rem;
}
.br-8{
  border-radius: 0.5rem !important;
}

.rounded-\[40px\]{
  border-radius: 2.5rem!important;
}

.rounded-md{
  border-radius: 0.375rem; /* 6px */

}

.border-grey{
  border-color: var(--border-color);
}
.border-dark{
  border-color: var(--border-dark);
}
.border-dark-2{
  border-color: var(--text-gray-2);
}

.border-light-2{
  border-color:var(--border-light);
}
.border-light-3{
  border-color: var(--border-light-3);
}

.border-block{
  border: 1px solid #A099D9
}
.border-red{
  border: 1px solid red!important;
}
.border-none{
  border: none;
}
.border-1{
  border-width: 1px;
}
.rounded-b{
  border-bottom-right-radius: 0.25rem; /* 4px */
  border-bottom-left-radius: 0.25rem; /* 4px */
}
.rounded-b-md{
  border-bottom-right-radius: 0.375rem; /* 6px */
  border-bottom-left-radius: 0.375rem; /* 6px */
}

a:hover{
  text-decoration: none;
}

.button{
  /* border: none; */
  /* border-radius: 6px; */
  border-radius: 0.375rem;
 
  padding: 0 1.25rem;
  box-shadow: 0px 2px 4px 0px var(--shadow);
}

.primary-btn{
  background-color: var(--primary);
  /* border-radius: 6px; */
  border-radius: 0.375rem;
  /* height: 42px; */
  height: 2.625rem;
  padding: 0 1.25rem;
  box-shadow: 0px 2px 4px 0px var(--shadow);
}

.truncated-text{
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
a {
  text-decoration: none;
  color: inherit;
}

.z-higher{
  z-index: 99999;
}
.z-top{
  z-index: 99999 !important;
}

.z-10{
  z-index: 10;
}
.placeholder\:font-medium::placeholder{
  font-weight: 400;
  color: #898989;
  margin: 0;
  padding: 0;
}

.placeholder\:tracking-wide::placeholder{
  letter-spacing: 0.05em
}

.focus-within\:outline-red-500:focus-within{
  outline-color: var(--primary)
}

.contentContainer {
  padding: 20px;
  width: calc(100% - 280px);

  /* @include lg {
    width: calc(100% - 80px);
  } */
}


.mx-auto{
  margin-left: auto;
  margin-right: auto
}
.bg-assign-role {
  background: #D6EEEF;
}

.bg-change-role {
  background: #EEEEEE;
}
.bg-activate{
  background: #D2FFBD;
  color: #464646;
}

.bg-linearGradient{
  background: linear-gradient(180deg, #403594 0%, #A39DCB 100%);
}
.bg-linearGradient-2{
  background: linear-gradient(147.47deg, #454351 30.53%, #9C98B7 137.91%);
}

.space-x-2 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse))
}


.p-24{
  padding: 6rem
}

.pb-20{
  padding-bottom: 5rem
}

.pb-28{
  padding-bottom: 7rem
}

.pb-32{
  padding-bottom: 8rem
}

.pb-36{
  padding-bottom: 9rem
}

.pb-40{
  padding-bottom: 10rem
}


.pb-48{
  padding-bottom: 12rem
}

.pt-10{
  padding-top: 2.5rem
}

.pt-14{
  padding-top: 3.5rem
}

.pt-16{
  padding-top: 4rem
}

.pt-20{
  padding-top: 5rem
}


.bg-opacity-90{
  opacity: 0.9;

}



